@import url("https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Devanagari:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');




@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
 --primary: #DA1F26;
 --black: #000000;
 --white: #FFFFFF;
 --light-black: #333333;
 --light-white: #EEEEEE;
 --primary-blue:#1e5799;

 --primary-rgb: #DA1F26;
 --black-rgb: #000000;
 --white-rgb: #FFFFFF;
 --light-black-rgb: #333333;
 --light-white-rgb: #EEEEEE;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  /* font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", 
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background: rgb(0, 0, 0);
  color: #333333;
  cursor: none;
}

.slick-prev:before,
.slick-next:before {
  /* color: var(--primary) !important; */
  color: var(--primary-blue) !important;
  font-size: 40px !important;
}

button {
  background: transparent;
  border: 0;
  outline: none;
}

input:focus,
textarea:focus {
  outline: none;
  background: var(--primary);
  color: white;
}

.contact-form input:focus,
.contact-form textarea:focus {
  background: transparent;
  color: black;
}

.contact-form textarea {
  resize: none;
}

.slick-prev {
  left: -65px !important;
}

.slick-next {
  right: -65px !important;
}

.styled-quote {
  position: relative;
  padding: 20px;
  /* Add any desired padding to create some space around the quote */
}

.styled-quote::before,
.styled-quote::after {
  font-size: 80px;
  /* Adjust the font-size to make the quote sign larger or smaller */
  color: #ababab;
  /* Adjust the color of the quote sign */
  position: absolute;
  font-family: "Space Grotesk";
  font-weight: bold;
}

.styled-quote::before {
  content: "\201C";
  /* Unicode character for left double quotation mark (starting quote) */
  top: 0;
  left: 0;
}

.styled-quote::after {
  content: "\201D";
  /* Unicode character for right double quotation mark (ending quote) */
  bottom: 0;
  right: 0;
}

html,
body {
  scroll-behavior: smooth;
}

a,
a:hover {
  text-decoration: none;
}

a {
  color: inherit;
}

.bg-primary {
  /* background: var(--primary); */
  background: var(--primary-blue);
}

.text-primary {
  color: var(--primary);
}

.btn-container .btn {
  position: relative;
  top: 0;
  left: 0;
  width: 250px;
  height: 50px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-container .btn a,
.btn-container .btn button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  padding: 10px;
  letter-spacing: 1px;
  text-decoration: none;
  overflow: hidden;
  font-weight: 400px;
  z-index: 1;
  transition: 0.5s;
  backdrop-filter: blur(15px);
  color: white;
}

.btn-container .btn.light a:hover,
.btn-container .btn.light button:hover {
  color: black;
}

.btn-container .btn:hover a,
.btn-container .btn:hover button {
  letter-spacing: 3px;
}

.btn-container .btn a::before,
.btn-container .btn button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
  transform: skewX(45deg) translate(0);
  transition: 0.5s;
  filter: blur(0px);
}

.btn-container .btn:hover a::before,
.btn-container .btn:hover button::before {
  transform: skewX(45deg) translate(200px);
}

.btn-container .btn::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
  bottom: -5px;
  width: 30px;
  height: 10px;
  border-radius: 10px;
  transition: 0.5s;
  transition-delay: 0.5;
}

.btn-container .btn.dark::before {
  background: var(--primary);
}

.btn-container .btn.light::before {
  background: white;
}

.btn-container .btn:hover::before

/*lightup button*/ {
  bottom: 0;
  height: 50%;
  width: 80%;
  border-radius: 30px;
}

.btn-container .btn::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
  top: -5px;
  width: 30px;
  height: 10px;
  background: var(--primary);
  border-radius: 10px;
  transition: 0.5s;
  transition-delay: 0.5;
}

.btn-container .btn:hover::after

/*lightup button*/ {
  top: 0;
  height: 50%;
  width: 80%;
  border-radius: 30px;
}

.btn-container .btn.dark:nth-child(1)::before,
/*chnage 1*/
.btn-container .btn.dark:nth-child(1)::after {
  background: var(--primary);
  box-shadow: 0 0 5px var(--primary), 0 0 15px var(--primary), 0 0 30px var(--primary),
    0 0 60px var(--primary);
}

.btn-container .btn.light:nth-child(1)::before,
/*chnage 1*/
.btn-container .btn.light:nth-child(1)::after {
  background: white;
  box-shadow: 0 0 5px white, 0 0 15px white, 0 0 30px white, 0 0 60px white;
}

nav .nav-items a {
  margin-right: 20px;
  position: relative;
}

nav .nav-items a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 3px;
  transform: scaleX(0);
  background-color: var(--primary);
  transition: transform 0.3s;
}

nav .nav-items a:hover:after {
  transform: scaleX(1);
}

canvas#tech-lines-animation {
  position: absolute;
  z-index: -10;
  top: 0;
  left: 0;
}

.canvas-overlay {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -8;
}

.cursor {
  display: block;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  border-radius: 50%;
  background: transparent;
  pointer-events: none;
  z-index: 999999999;
  /* border: 2px solid var(--primary); */
  border: 2px solid var(--primary-blue);
  padding: 3px;
}

.cursor .inner-circle {
  /* background: var(--primary); */
  background: var(--primary-blue);
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.expand {
  background: transparent;
  animation: moveCursor2 0.6s forwards;
  border: 1px solid var(--primary);
}

@keyframes moveCursor2 {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(2);
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes fill {
  0% {
    transform: scale(1);
    background: transparent;
  }

  50% {
    transform: scale(0.8);
    /* background: var(--primary); */
    background: var(--primary-blue);
  }

  100% {
    background: transparent;
    transform: scale(1);
  }
}

.homepage .bg-circle {
  border-radius: 50%;
  position: absolute;
  opacity: 0.6;
}

.bg-dots > div {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  /* background: red; */
  background: #1e5799;
  /* box-shadow: 0px 1px 2px rgba(255, 0, 0, 0.5), 0px 2px 4px rgba(255, 0, 0, 0.5),
    0px 4px 8px rgba(255, 0, 0, 0.5), 0px 8px 16px rgba(255, 0, 0, 0.5); */
    box-shadow: 
    0px 1px 2px rgba(30, 87, 153, 0.5),  /* lighter blue shadow */
    0px 2px 4px rgba(30, 87, 153, 0.5),
    0px 4px 8px rgba(30, 87, 153, 0.5),
    0px 8px 16px rgba(30, 87, 153, 0.5); /* darker blue shadow */

}

.banner-container {
  /* background: linear-gradient(180deg, rgb(0 0 0) 0%, var(--primary) 100%); */
  background: linear-gradient(180deg, rgb(0 0 0) 0%, var(--primary-blue) 100%);
  
}

.rev-banner-container {
  background: linear-gradient(180deg, var(--primary) 100%), rgb(0 0 0) 0%;
}

.laptop-image {
  animation: float 5s infinite;
  filter: brightness(0.6);
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;

  border-bottom: 15px solid black;
  border-radius: 5px;
}

@keyframes float {
  0% {
    transform: translateY(-8px);
  }

  50% {
    transform: translateY(8px);
  }

  100% {
    transform: translateY(-8px);
  }
}

section#about-gallery {
  --childs: 3;
  --gap: 4px;
  width: 100%;
  display: flex;
  gap: var(--gap);
}

section#about-gallery .box {
  width: calc((100% / var(--childs)));
  filter: grayscale(1);
  box-sizing: border-box;
  height: min(40vmin, 250px);
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  transition: width 500ms ease-in-out;
}

section#about-gallery .box:hover {
  filter: grayscale(0);
  width: calc((100% / (var(--childs) - 2)));
}

.products-separator {
  height: 3px;
  background-color: #b11116;
  box-shadow: 0 0 5px 5px #ff040c;
}

.contact-form-popup-left {
  /* background-image: url("https://img.freepik.com/free-vector/paper-style-white-monochrome-background_23-2149023490.jpg?size=626&ext=jpg&ga=GA1.1.632798143.1705363200&semt=ais"); */
  background-size: cover;
  background-position: center;
  background-color: #00000030;
  background-image: url("../public/assets/bg/form-bg.jpg");
  background-blend-mode: overlay;
}

.modal-container:focus,
.modal-container:active {
  outline: none;
  border: 0;
}

.contact-header {
  background-image: url("https://images.unsplash.com/uploads/141103282695035fa1380/95cdfeef?q=80&w=2030&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-size: cover;
  background-position: top;
  background-color: rgba(255, 0, 0, 0.8);
  background-blend-mode: overlay;
}

@media all and (max-width: 600px) {
  .our-team-grid > .relative > .absolute {
    left: 0;
    top: 300px;
  }

  .our-team-grid > .relative {
    margin-bottom: 150px;
  }

  .contact-form-popup-left {
    border-right: 0;
    border-bottom-width: 5px;
  }

  .our-team-grid .team-info-separator {
    display: none;
  }

  .our-team-grid .team-info {
    height: 50%;
  }

  @media all and (max-width: 800px) {
    .hero-padding {
      padding: 0 10px 0 20px;
    }

    #root {
      width: min-content;
    }

    /* .hero .relative:first-child {
      margin-top: 22%;
    } */

    .faq-header p {
      padding: 0 45px;
    }

    .faq-header {
      text-align: center;
    }

    nav {
      padding-right: 0;
    }

    .product-item {
      padding-top: 50px;
    }

    .product-item img {
      width: 85%;
    }

    .product-item-3 img {
      margin-bottom: 40px;
    }

    .product-item,
    .product-item-2,
    .product-item-3 {
      flex-direction: column;
    }

    .product-item-3 .thumb {
      margin-bottom: -50px;
    }

    .product-item-2 {
      padding-top: 25px;
    }

    .cursor {
      display: none;
    }

    .product-item > .flex {
      margin: 60px 0 80px 0;
    }
  }
}

/* SERVICES */
.holderCircle {
  width: 500px;
  height: 500px;
  border-radius: 100%;
  margin: 10px auto;
  position: relative;
}

.round {
  position: absolute;
  left: 40px;
  top: 45px;
  width: 410px;
  height: 410px;
  border: 2px dotted var(--primary);
  border-radius: 100%;
  -webkit-animation: rotation 100s infinite linear;
}

.dotCircle {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 100%;
  z-index: 2;
}

.dotCircle .itemDot {
  display: block;
  width: 80px;
  height: 80px;
  position: absolute;
  background: #ffffff;
  /* color: var(--primary); */
  color: var(--primary-blue);
  /* color: #1e5799; */
  border-radius: 20px;
  text-align: center;
  z-index: 3;
  cursor: pointer;
  border: 2px solid #e6e6e6;
}

.dotCircle .itemDot .forActive::after {
  border: 3px solid var(--primary);
  filter: blur(1px);
  border-radius: 100%;
}

.dotCircle .itemDot .forActive::before {
  filter: blur(5px);
  transform: rotate(-45deg);
  border: 6px solid var(--primary);
}

.dotCircle .itemDot.active .forActive {
  display: flex;
}

.dotCircle .itemDot:hover,
.dotCircle .itemDot.active {
  color: #ffffff;
  transition: 0.5s;
  background: var(--primary);
  background: -moz-linear-gradient(left, var(--primary) 0%, #000000 100%);
  background: -webkit-linear-gradient(
    left,
    var(--primary) 0%,
    #000000 100%
  );
  /* background: linear-gradient(to right, var(--primary) 0%, #000000 100%); */
  background: linear-gradient(to right, var(--primary-blue) 0%, #000000 100%);
 
  
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='var(--primary)', endColorstr='#000000', GradientType=1);
  border: 2px solid #ffffff;
  -webkit-box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.13);
  box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.13);
}

.contentCircle {
  width: 200px;
  border-radius: 100%;
  color: #222222;
  position: relative;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contentCircle .CirItem {
  border-radius: 100%;
  color: #222222;
  position: absolute;
  text-align: center;
  bottom: 0;
  left: 0;
  opacity: 0;
  transform: scale(0);
  transition: 0.5s;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  margin: auto;
}

.CirItem.active {
  z-index: 1;
  opacity: 1;
  transform: scale(1);
  transition: 0.5s;
}

.contentCircle .CirItem i {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -90px;
  color: #000000;
  opacity: 0.1;
}

@media only screen and (max-width: 800px) {
  .holderCircle {
    width: 300px;
    height: 300px;
    margin: 110px auto;
  }

  .holderCircle::after {
    width: 100%;
    height: 100%;
  }

  .round {
    left: 0px;
    top: 0px;
    width: 300px;
    height: 300px;
  }

  .dotCircle {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }

  .dotCircle .itemDot {
    width: 60px;
    height: 60px;
    color: var(--primary);
    border-radius: 10px;
  }

  .contentCircle {
    width: 200px;
    /* 250 */
    top: 30%;
  }

  .contentCircle .CirItem i {
    margin-left: -45px;
    /* 90 */
  }
}

.title-box .title {
  position: relative;
  z-index: -1;
}

.title-box span {
  text-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
  /* color: var(--primary); */
  color: var(--primary-blue);
}
/* SERVICES END */

/* OUR TEAM */
.team {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 50px;
  row-gap: 70px;
  padding: 50px 0px;
}
.team .profile {
  display: flex;
  justify-content: center;
  position: relative;
}
.team .profile figure {
  width: 85%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.team .profile figure img {
  width: 80%;
}
.team .profile figure figcaption {
  font-size: 16px;
  font-weight: 500;
  margin-top: 12px;
  text-transform: capitalize;
  cursor: pointer;
}
.team .profile figure::after {
  content: attr(data-value);
  width: 100%;
  transform-origin: 0 0;
  transform: rotate(90deg);
  position: absolute;
  text-transform: uppercase;
  font-size: 12px;
  right: -92%;
}
.team .profile:nth-child(2) {
  margin-top: 70px;
}
.team .profile:nth-child(4) {
  margin-top: -70px;
}
.team .profile:nth-child(6) {
  margin-top: -70px;
}

@media screen and (max-width: 1440px) {
  .team {
    width: 70%;
    column-gap: 50px;
  }
}
@media screen and (max-width: 1024px) {
  .team {
    width: 80%;
    column-gap: 35px;
  }
}
@media screen and (max-width: 768px) {
  .team {
    width: 90%;
    column-gap: 25px;
  }
}
@media screen and (max-width: 600px) {
  .team {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0px;
    row-gap: 0px;
  }
  .team .profile:nth-child(2),
  .team .profile:nth-child(4),
  .team .profile:nth-child(6),
  .team .profile:nth-child(8) {
    margin-top: 50px;
  }
  .team .profile:nth-child(3),
  .team .profile:nth-child(5),
  .team .profile:nth-child(7),
  .team .profile:nth-child(9) {
    margin-top: 0px;
  }
}
/* OUR TEAM END */

/* OUR CLIENTS */
.logogrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-auto-rows: 75px;
  grid-gap: 2rem;
}

.logogrid__item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.logogrid__img {
  -o-object-fit: cover;
  object-fit: cover;
  max-width: 120px;
  max-height: 60px;
}

.clients {
  display: flex;
  flex-direction: column;
  justify-items: center;
  min-height: 10vh;
}

.clients-container {
  width: 90%;
  max-width: 1000px;
  margin: auto;
}

.slick-initialized .slick-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
/* OUR CLIENTS END */

/* ABOUT GALLERY */
.mfp-with-zoom .mfp-title,
.touch .gallery-image figcaption,
html:not(.touch) .gallery-image figcaption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: rgba(255, 255, 255, 0);
  padding: 1em;
  transition: all 0.2s ease;
  font-weight: 600;
  max-width: calc(100% - 9em);
  line-height: 1.25;
  text-align: center;
  box-sizing: border-box;
}
.mfp-with-zoom .mfp-title:before,
.touch .gallery-image figcaption:before,
html:not(.touch) .gallery-image figcaption:before,
.mfp-with-zoom .mfp-title:after,
.touch .gallery-image figcaption:after,
html:not(.touch) .gallery-image figcaption:after {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  padding: 1em;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  z-index: -1;
}
.mfp-with-zoom .mfp-title:before,
.touch .gallery-image figcaption:before,
html:not(.touch) .gallery-image figcaption:before,
.mfp-with-zoom .mfp-title:after,
.touch .gallery-image figcaption:after,
html:not(.touch) .gallery-image figcaption:after {
  right: 100%;
  bottom: 100%;
}
.mfp-with-zoom .mfp-title:after,
.touch .gallery-image figcaption:after,
html:not(.touch) .gallery-image figcaption:after {
  left: 100%;
  top: 100%;
}

.mfp-with-zoom.mfp-ready .mfp-title,
.touch .gallery-image figcaption,
html:not(.touch) .gallery-image:hover figcaption {
  color: white;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease 0.3s;
}
.mfp-with-zoom.mfp-ready .mfp-title:before,
.touch .gallery-image figcaption:before,
html:not(.touch) .gallery-image:hover figcaption:before,
.mfp-with-zoom.mfp-ready .mfp-title:after,
.touch .gallery-image figcaption:after,
html:not(.touch) .gallery-image:hover figcaption:after {
  opacity: 1;
}
.mfp-with-zoom.mfp-ready .mfp-title:before,
.touch .gallery-image figcaption:before,
html:not(.touch) .gallery-image:hover figcaption:before {
  right: -1.5em;
  bottom: -1.5em;
}
.mfp-with-zoom.mfp-ready .mfp-title:after,
.touch .gallery-image figcaption:after,
html:not(.touch) .gallery-image:hover figcaption:after {
  left: -1.5em;
  top: -1.5em;
}
.gallery {
  -moz-column-gap: 0;
  column-gap: 0;
  -moz-column-count: 2;
  column-count: 2;
}
@media (min-width: 480px) {
  .gallery {
    -moz-column-count: 3;
    column-count: 3;
  }
}
@media (min-width: 1260px) {
  .gallery {
    -moz-column-count: 4;
    column-count: 4;
  }
}

.gallery-image {
  position: relative;
  margin: 0;
  padding: 0;
}
.gallery-image:before,
.gallery-image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 16px solid rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
  will-change: border;
}
.gallery-image:after {
  border-width: 0;
}
.gallery-image img {
  display: block;
  max-width: 100%;
  height: auto;
}
html:not(.touch) .gallery-image {
  overflow: hidden;
}
html:not(.touch) .gallery-image:hover:before {
  border-width: 16px;
}
html:not(.touch) .gallery-image:hover:after {
  border-width: 32px;
}
.touch .gallery-image figcaption {
  top: auto;
  bottom: 2em;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.3s ease-out;
}
.mfp-with-zoom.mfp-bg {
  background-color: rgba(0, 0, 0, 0.9);
}
.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 1;
}
.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}
.mfp-with-zoom img.mfp-img {
  padding: 0;
}
.mfp-with-zoom .mfp-figure:after {
  top: 0;
  bottom: 0;
}
.mfp-with-zoom .mfp-container {
  padding: 0;
}
.mfp-with-zoom .mfp-content {
  overflow: hidden;
}
.mfp-with-zoom .mfp-bottom-bar {
  top: auto;
  bottom: 0;
  margin-top: 0;
}
.mfp-with-zoom .mfp-title {
  top: auto;
  bottom: 2em;
}
.mfp-arrow {
  opacity: 1;
  margin-top: 0 !important;
  width: 20%;
  height: 30%;
  transform: translateY(-50%);
}
.mfp-arrow:before,
.mfp-arrow:after {
  margin: 0;
  border: none;
  width: 2rem;
  height: 2rem;
  transform: rotate(-45deg) translate(-50%, -100%);
  opacity: 1;
  top: 50%;
  left: 50%;
  transition: all 0.15s;
}
.mfp-arrow:active {
  transform: translateY(-50%) scale(0.95);
}

.mfp-arrow-left {
  left: 0;
}
.mfp-arrow-left:before,
.mfp-arrow-left:after {
  border-top: 2px solid white;
  border-left: 2px solid white;
}
.mfp-arrow-left:after {
  margin-left: 2rem;
}
.mfp-arrow-left:hover:before,
.mfp-arrow-left:active:before {
  margin-left: 2rem;
}
.mfp-arrow-left:hover:after,
.mfp-arrow-left:active:after {
  margin-left: 0;
}

.mfp-arrow-right {
  right: 0;
}
.mfp-arrow-right:before,
.mfp-arrow-right:after {
  border-right: 2px solid white;
  border-bottom: 2px solid white;
}
.mfp-arrow-right:after {
  margin-left: 2rem;
}
.mfp-arrow-right:hover:before {
  margin-left: 2rem;
}
.mfp-arrow-right:hover:after {
  margin-left: 0;
}

button.mfp-close {
  opacity: 1;
  margin-top: 0 !important;
  width: 20%;
  height: 30%;
  font: 0/0 serif;
  text-shadow: none;
  color: transparent;
}
button.mfp-close:before,
button.mfp-close:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  border: none;
  width: 2rem;
  height: 2rem;
  opacity: 1;
  transition: all 0.15s;
  transform-origin: 0 0;
  border-top: 2px solid white;
  border-left: 2px solid white;
}
button.mfp-close:before {
  transform: rotate(-45deg);
}
button.mfp-close:after {
  transform: rotate(135deg);
}
button.mfp-close:hover:before {
  transform: rotate(135deg);
}
button.mfp-close:hover:after {
  transform: rotate(315deg);
}
button.mfp-close:active {
  transform: scale(0.95);
}

a {
  text-decoration: none;
  color: inherit;
}
/* ABOUT GALLERY END */



/* hero text animation */
/* Define the keyframes for the slide-up effect */
@keyframes slide-up {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Apply the animation to the h1 and p */
.slide-up {
  animation: slide-up 0.8s ease-out forwards;
  opacity: 0; /* Start as invisible */
}

/* Add a delay to the <p> tag animation */
.delay {
  animation-delay: 0.2s;
}

/* hero text animation */
/* Define the keyframes for the slide-up effect */
@keyframes title-slide {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 0.1;
  }
}

/* Apply the animation to the h1 and p */
.title-slide {
  animation: title-slide 0.8s ease-out forwards;
  opacity: 0; /* Start as invisible */
}


/* background */
.overall-bg {
  position: relative;/* Ensures stacking context for other elements*/
  /* background: radial-gradient(circle at 50% 50%, #0a1633, #0a0d1a, #000000); */
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

/* particles background effect  */

/* Ensure ParticleBackground is behind other components */
.particle-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1; /* Set a lower z-index */
}

.other-content {
  position: relative;
  z-index: 1; /* Higher z-index so content is above background */
}


/* custom scrolls */
::-webkit-scrollbar {
  width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  /* background:  var(--primary-blue);  */
  background: linear-gradient(180deg, rgb(255 255 255) 0%, var(--primary-blue) 100%); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: linear-gradient(180deg, rgb(0 0 0) 0%, var(--primary-blue) 100%);  */
  background:  var(--primary-blue); 

}

/* Firefox */
/* html {
  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: thin;
} */




.container {
  /* position: relative; */
  position: fixed;
  background: radial-gradient(circle at 50% 50%, #0a1633, #0a0d1a, #000000);
  /* background: linear-gradient(135deg, #0a2e57, #1b3b6f, #0f2a47, #000000); */

  filter: brightness(0.9);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none; 
  background-blend-mode: screen;
  background-size: cover;
  /* filter: grayscale(100%); */
  z-index: -1;
}

/* Particle styles */
.circle-container {
  position: absolute;
  width: 5px; /* Smaller particle size */
  height: 5px;
  animation: move 15s linear infinite; /* Slower movement */
  pointer-events: none;
}

.circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: radial-gradient(
    hsla(180, 100%, 80%, 0.7),
    hsla(180, 100%, 80%, 0.2) 60%,
    hsla(180, 100%, 80%, 0) 100%
  );
  mix-blend-mode: screen;
  animation: pulse 4s ease-in-out infinite;
  opacity: 0.5;
}

/* Pulse animation for individual particles */
@keyframes pulse {
  0% { transform: scale(0.4); opacity: 0.5; }
  50% { transform: scale(1.5); opacity: 0.5; }
  100% { transform: scale(0.4); opacity: 0.5; }
}

/* Custom movement for each particle */
@keyframes move {
  from { transform: translateY(100vh) translateX(0); }
  to { transform: translateY(-100vh) translateX(0); }
}



